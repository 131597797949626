import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { widState } from "../../../Store/workdriveState";
import { useSnapshot } from "valtio";
import DynamicHashTextField from "../../Others/DynamicHashTextField/DynamicHashTextField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import ClipboardCopy from "../../RelatedList/srcfolder/components/WorkDrive/Modals/ClipboardCopy";
const ZOHO = window.ZOHO;
export default function EditAction({
  fieldOptions,
  moduleName,
  setPage,
  existingData,
  dataCenterUrl,
}) {
  const snap = useSnapshot(widState);
  const [fieldApiName, setFieldApiName] = useState(null);
  const [recordFolderName, setRecordFolderName] = useState("");
  const [finalSaveLoading, setFinalSaveLoading] = useState(false);

  useEffect(() => {
    const prevSettings = existingData?.setting_schema
      ? JSON.parse(existingData?.setting_schema)
      : {};

    if (prevSettings?.fieldApiName) {
      setFieldApiName(prevSettings?.fieldApiName);
    }
    if (prevSettings?.recordFolderName) {
      setRecordFolderName(prevSettings?.recordFolderName);
    }
  }, [existingData]);

  const handleSave = async (event) => {
    event.preventDefault();
    setFinalSaveLoading(true);
    let data = {
      moduleApiName: moduleName,
      fieldApiName: fieldApiName,
      recordFolderName: recordFolderName,
      settingType: "relatedList",
    };

    const insertedData = await axios.request({
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
      method: "POST",
      data: {
        settingId: widState?.settingId || "",
        settingSchema: data,
        showInRelatedList: false,
        moduleApiName: moduleName,
        settingType: "workflow",
      },
      headers: {
        orgid: widState.orgId,
        apikey: widState.apiKey,
        datacenterurl: widState.dataCenterUrl,
        connname: "easyworkdriveforcrm",
      },
    });

    const settingId = insertedData?.data?.data?.setting_id || "";

    if (settingId !== "") {
      await ZOHO.CRM.ACTION.setConfig({
        settingId: settingId,
        orgid: widState.orgId,
        datacenterurl: widState.dataCenterUrl,
        actionType: "Edit Action",
        // apikey: snap.apiKey,
        connname: "easyworkdriveforcrm",
      });
    }
    setFinalSaveLoading(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: existingData?.setting_id && "space-between",
          gap: 2,
          alignItems: "center",
          mx: 3,
          my: 1,
        }}
      >
        {setPage && (
          <ArrowBackIcon
            sx={{ cursor: "pointer" }}
            onClick={() => setPage(null)}
          />
        )}
        <Typography sx={{ fontSize: "24px", fontWeight: 500 }}>
          Edit Folder
        </Typography>
        {existingData?.setting_id ? (
          <Box
            sx={{
              mt: 1.1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Typography sx={{ fontWeight: "medium" }}>Setting Id:</Typography>
            <Typography sx={{ ml: 0.3, fontSize: 14, mt: 0.2 }}>
              {existingData?.setting_id}
            </Typography>
            <ClipboardCopy
              color={"transparent"}
              copyText={existingData?.setting_id}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box sx={{ mb: 1, mx: 3 }}>
        <Typography sx={{ mb: 1, fontSize: 18 }}>
          Please Provide Necesarry Details
        </Typography>
        <Divider />
        <Typography sx={{ my: 2, fontSize: 16 }}>
          In some scenerio, you may want to Rename Folder Name based on some
          field values in the process, like Status Change or Stage Change. It
          allows you to Change Folder Name when Workflow Triggers
        </Typography>
        <br />
        <Typography sx={{ mb: 1, mt: 1 }}>
          <strong>{moduleName}</strong> Field Name contains Folder ID
        </Typography>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={
            fieldApiName?.display_name
              ? {
                  field_label: fieldApiName?.display_name,
                }
              : null
          }
          options={fieldOptions.filter(
            (indv) => !indv?.api_name?.includes(".")
          )}
          getOptionLabel={(option) => option.field_label}
          onChange={(e, v) => {
            if (v) {
              setFieldApiName({
                display_name: v.field_label,
                api_name: v.api_name,
              });
            } else {
              setFieldApiName(null);
            }
          }}
          size="small"
          sx={{ width: 350 }}
          renderInput={(params) => <TextField {...params} />}
        />

        <Typography sx={{ mt: 2, mb: 1 }}>
          New Folder Name (Use # to use Merge Fields)
        </Typography>
        {/* // module */}
        <DynamicHashTextField
          module={moduleName}
          fields={[]}
          initialValue={recordFolderName}
          savedData={(data) => {
            setRecordFolderName(data);
          }}
        />
      </Box>
      <Box sx={{ position: "fixed", bottom: 14, right: 10 }}>
        <Button
          onClick={() => {
            setRecordFolderName("");
            setFieldApiName(null);
          }}
          // disabled={step === 5 ? false : true}
          size="small"
          sx={{ width: 100, mr: 1.5 }}
          variant="outlined"
        >
          Reset
        </Button>
        <Button
          onClick={handleSave}
          disabled={fieldApiName && recordFolderName ? false : true}
          size="small"
          sx={{ width: 100 }}
          variant="contained"
        >
          {finalSaveLoading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            "Save"
          )}
        </Button>
      </Box>
    </Box>
  );
}
